<script>
import { mapState } from 'vuex';
import AAComponent from '@/app/AAComponent';

export default {
  name: 'PartnerFinderApp',
  data: () => ({
    queryData: '',
  }),
  components: {
    AAComponent,
  },
  computed: {
    ...mapState('partnerProfile', {
      pfProfileData: (state) => state.profileData,
    }),
  },
  mounted() {
    this.getScrollBarWidth();
  },
  beforeCreate() {
    this.$store.dispatch('fullData/fetchAllData');
  },
  created() {
    if (this.$store) {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'profileSearch/setResults') {
          this.$store.commit('profileSearch/setPrevQuery');
        }
      });
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        switch (to.name) {
          case 'home':
            document.title = `${this.$t('title.home')}`;
            break;
          case 'partnerNavigatorPage':
            document.title = `${this.$t('title.partnerNavigatorPage')}`;
            break;
          case 'profilePage':
            this.$store.dispatch('autocomplete/setQueryValue', '');
            break;
          default:
            document.title = `${this.$t('title.home')}`;
        }
      },
      deep: true,
    },
    pfProfileData(val) {
      if (val.name && this.$route.name === 'profilePage') {
        document.title = `${this.$t('title.profilePage', { partnerName: val.name })}`;
      } else if (val.name && this.$route.name === 'profileEditorPagePreview') {
        document.title = `${this.$t('title.profilePreviewPage', { partnerName: val.name })}`;
      } else if (val.name && this.$route.name === 'profileEditorPageEdit') {
        document.title = `${this.$t('title.profileEditorPage', { partnerName: val.name })}`;
      }
    },
  },
  methods: {
    updateSearchQuery(data) {
      this.queryData = data;
    },
    getScrollBarWidth() {
      const inner = document.createElement('p');
      inner.style.width = '100%';
      inner.style.height = '200px';

      const outer = document.createElement('div');
      outer.style.position = 'absolute';
      outer.style.top = '0px';
      outer.style.left = '0px';
      outer.style.visibility = 'hidden';
      outer.style.width = '200px';
      outer.style.height = '150px';
      outer.style.overflow = 'hidden';
      outer.appendChild(inner);

      document.body.appendChild(outer);
      const w1 = inner.offsetWidth;
      outer.style.overflow = 'scroll';
      let w2 = inner.offsetWidth;
      if (w1 === w2) w2 = outer.clientWidth;

      document.body.removeChild(outer);
      this.$store.commit('partnerProfile/setScrollBarWidth', w1 - w2);
    },
  },
};
</script>

<template>
  <div id="app" class="ds-flexGrid ds-p-none">
    <AAComponent/>
    <header class="ds-flexRow ds-m-none">
      <router-view name="siteheader"></router-view>
    </header>
    <main class="ds-flexRow ds-m-none">
      <router-view name="contentarea"></router-view>
    </main>
    <footer class="ds-flexRow ds-m-none">
      <router-view name="sitefooter"></router-view>
    </footer>
  </div>
</template>
