import axios from 'axios';
import router from '../../router/routes';

const ROOT_ENDPOINT = '/sap/details/api';
const SolutionL2Store = {
  namespaced: true,
  state: {
    // store IE competency objects
    ieCompetency: [],
    updateSolutionFlag: false,
    buildInitSolutionFlag: false,
    // map L2 to L1  <L2_ID, L1_ID>
    reverseL2L1Mapping: new Map(),
    // map title to L2  <title, L2_ID>
    titleL2Mapping: new Map(),
    mobileMode: false,
    // store sortedSolution and show it in the filter dropdown
    sortedSolution: [],

    // store objects with solutions which does not have Cloud Competency specialization
    hasNoSpecializationItems: [],

    //* *Map<solution_ID, parent_ID/''>
    solutionBadge: new Map(),

    // this Map need to keep the status of each L1&L2
    solutionDataMap: new Map(),
    // Map<L1_ID, L1Object>
    // -- in the L1Object: {
    //  **L1_title<title>: STRING
    //    status: None<0>/Partially<1>/All selected<2>, <- needs Number here for sorting!!!
    //    available: 0/1,
    //    count: number,
    //    order: number,
    //    L2Data:Map<L2_ID, L2Object>
    //  }
    //    -- in the L2Object: {
    //  **L2_title<title>: STRING
    //    status:  None<0>/All selected<1>, <- needs Number here for sorting!!!
    //    available: 0/1,
    //    order: number,
    // }
  },
  getters: {
    // Checks if all `solutionBadge` keys exist in `hasNoSpecializationItems`and return boolean.
    isBadgeConsistentWithUnavailableItems: (state) => {
      const unavailableKeys = new Set(state.hasNoSpecializationItems.map((item) => item.key));
      const badgeKeys = Array.from(state.solutionBadge.keys());
      return badgeKeys.length > 0 && badgeKeys.every((key) => unavailableKeys.has(key));
    },
  },
  actions: {
    buildSolutionDataMap(context, val) {
      // val should be search.distributions.PRODUCTS
      context.commit('resetSolutionDataMap');
      context.commit('buildSolutionDataMap', val);
    },
    unselectAllSolution(context, updateSolution) {
      // build remove solution in url & router
      context.commit('clearAllSelectedSolution');
      context.commit('sortSolution');
      const type = 'products';
      context.dispatch('profileSearch/uncheckAllOptionsByType', { type, updateSolution }, { root: true });
    },
    clearSelectedSolution(context) {
      context.commit('clearAllSelectedSolution');
      context.commit('sortSolution');
    },
    searchSolution(context, val) {
      if (val && val.length > 0) {
        context.commit('searchInSortedSolutionV2', val);
      } else {
        context.commit('sortSolution');
      }
    },
    addOrRemoveSolutionToUrl(context, { val, add }) {
      // val should be{L2_ID, L1_ID} or {L1_ID}
      const optionType = 'products';
      const options = [];
      if (val.L2) {
        // then it's L2 added
        const L2Title = context.state.solutionDataMap.get(val.L1).L2Data.get(val.L2).title;
        const solutionValue = {
          title: L2Title,
          isSelected: true,
        };
        options.push({ optionType, value: solutionValue });
      } else {
        // L1 ID need to build array for each available L2 ID
        const L2IdArray = [...context.state.solutionDataMap.get(val.L1).L2Data.keys()];
        L2IdArray.forEach((L2Id) => {
          if (context.state.solutionDataMap.get(val.L1).L2Data.get(L2Id).available == 1) {
            const L2Title = context.state.solutionDataMap.get(val.L1).L2Data.get(L2Id).title;
            const solutionValue = {
              title: L2Title,
              isSelected: true,
            };
            options.push({ optionType, value: solutionValue });
          }
        });
      }
      if (add) {
        context.dispatch('profileSearch/addSelectedOptions', { options }, { root: true });
      } else {
        context.dispatch('profileSearch/uncheckOptions', { options }, { root: true });
      }
    },
    addSelectedL1Item(context, val) {
      context.commit('addSelectedL1Item', val);
      context.dispatch('addOrRemoveSolutionToUrl', { val, add: true });
    },
    removeSelectedL1Item(context, val) {
      context.commit('removeSelectedL1Item', val);
      context.dispatch('addOrRemoveSolutionToUrl', { val, add: false });
    },
    addSelectedL2Item(context, val) {
      // val is {L1_ID, L2_ID}
      context.commit('addSelectedL2Item', val);
      context.dispatch('addOrRemoveSolutionToUrl', { val, add: true });
    },
    removeSelectedL2Item(context, val) {
      // val is {L1_ID, L2_ID}
      context.commit('removeSelectedL2Item', val);
      context.dispatch('addOrRemoveSolutionToUrl', { val, add: false });
    },
    restoreSelectedSolution(context) {
      const urlParameters = new URLSearchParams(window.location.search);
      (urlParameters.getAll('products') || []).forEach((product) => {
        const L2Id = context.state.titleL2Mapping.get(product);
        const L1L2Obj = {
          L1: context.state.reverseL2L1Mapping.get(L2Id),
          L2: L2Id,
        };
        context.commit('addSelectedL2Item', L1L2Obj);
      });
    },
    async updateSolutionAfterSearch(context, val) {
      if (context.state.buildInitSolutionFlag) {
        window.setTimeout(async () => {
          context.commit('setUpdateSolutionFlag', true);
          await context.dispatch('buildSolutionDataMap', val);
          await context.dispatch('restoreSelectedSolution');
          context.commit('setUpdateSolutionFlag', false);
        }, 500);
      } else {
        context.commit('setUpdateSolutionFlag', true);
        await context.dispatch('buildSolutionDataMap', val);
        await context.dispatch('restoreSelectedSolution');
        context.commit('setUpdateSolutionFlag', false);
      }
    },
  },
  mutations: {
    // Storing  all the solutions with NO cloud competency to compare with selected solution badges.
    // TODO: investigate time excecution during perfomance opt.
    setItemsWithSpecializationUnavailable(state) {
      state.solutionDataMap.forEach((value) => {
        const L2Data = value.L2Data;
        if (L2Data instanceof Map) {
          L2Data.forEach((innerValue, innerKey) => {
            if (!innerValue.specializationAvailable) {
              state.hasNoSpecializationItems.push({
                key: innerKey,
                ...innerValue,
              });
            }
          });
        }
      });
    },

    setIeCompetency(context, competencies) {
      context.ieCompetency = competencies;
    },
    setUpdateSolutionFlag(context, val) {
      context.updateSolutionFlag = val;
    },
    setBuildInitSolutionFlag(context, val) {
      context.buildInitSolutionFlag = val;
    },
    buildInitSolutionMap(context, val) {
      if (val.solutions) {
        val.solutions.forEach((L1Sol) => {
          const L1Object = {
            competencyAvailable: L1Sol.hasCompetency,
            title: L1Sol.name,
            status: 0,
            available: 0,
            order: L1Sol.sortOrder,
            L2Data: new Map(),
          };
          // build L2 map
          L1Sol.solutionsL2.forEach((L2Sol) => {
            const L2Object = {
              specializationAvailable: L2Sol.hasSpecialization,
              title: L2Sol.name,
              status: 0,
              available: 0,
              order: L2Sol.sortOrder,
            };
            L1Object.L2Data.set(L2Sol.id, L2Object);
            context.reverseL2L1Mapping.set(L2Sol.id, L1Sol.id);
            context.titleL2Mapping.set(L2Sol.name, L2Sol.id);
          });
          context.solutionDataMap.set(L1Sol.id, L1Object);
        });
      }
    },
    resetSolutionDataMap(context) {
      context.solutionDataMap.forEach((value, key) => {
        value.available = 0;
        value.L2Data.forEach((value, key) => {
          value.available = 0;
        });
      });
    },
    buildSolutionDataMap(context, val) {
      // val -> [{id: "PFOTH", title:'', count:123}]
      // transfer mockSolutionData(result response) to solutionDataMap
      val.filter((sol) => sol.count > 0).forEach((sol) => {
        const L1Key = context.reverseL2L1Mapping.get(sol.id);
        if (context.solutionDataMap.get(L1Key)) {
          context.solutionDataMap.get(L1Key).available = 1;
          context.solutionDataMap.get(L1Key).L2Data.get(sol.id).available = 1;
        }
      });
    },
    addSelectedL1Item(context, val) {
      // refresh status for the L1&L2 solution
      // val should be {L1: String} ---> {L1: ID}
      let L1SelectedFlag = 1;
      const selectedL2 = [];
      context.solutionDataMap.get(val.L1).L2Data.forEach((value, key) => {
        value.status = value.available ? 1 : 0;
        L1SelectedFlag = L1SelectedFlag && value.status;
        if (value.available) {
          selectedL2.push(key);
        }
      });
      context.solutionDataMap.get(val.L1).status = L1SelectedFlag ? 2 : 1;
      if (L1SelectedFlag) {
        context.solutionDataMap.get(val.L1).L2Data.forEach((value, key) => {
          context.solutionBadge.delete(key);
        });
        context.solutionBadge.set(val.L1, '');
      } else {
        selectedL2.forEach((item) => {
          context.solutionBadge.set(item, val.L1);
        });
      }
    },
    removeSelectedL1Item(context, val) {
      // refresh status for the L1&L2 solution
      // val should be {L1: ID}
      context.solutionDataMap.get(val.L1).status = 0;
      context.solutionDataMap.get(val.L1).L2Data.forEach((value, key) => {
        value.status = 0;
        context.solutionBadge.delete(key);
      });
      context.solutionBadge.delete(val.L1);
    },
    addSelectedL2Item(context, val) {
      // refresh status for the L1&L2 solution
      // val should be {L1: ID, L2: ID}
      context.solutionDataMap.get(val.L1).L2Data.get(val.L2).status = 1;
      let L1SelectedFlag = 1;
      context.solutionDataMap.get(val.L1).L2Data.forEach((value) => {
        L1SelectedFlag = L1SelectedFlag && value.status;
      });
      context.solutionDataMap.get(val.L1).status = L1SelectedFlag ? 2 : 1;
      context.solutionBadge.set(val.L2, val.L1);
      // disable L1 tag
      // if (L1SelectedFlag) {
      //   context.solutionDataMap.get(val.L1).L2Data.forEach((value, key) => {
      //     context.solutionBadge.delete(key);
      //   });
      //   context.solutionBadge.set(val.L1, '');
      // } else {
      //   context.solutionBadge.set(val.L2, val.L1);
      // }
    },
    removeSelectedL2Item(context, val) {
      // refresh status for the L1&L2 solution
      // val should be {L1: String, L2: String} ---> {L1: ID, L2: ID}
      context.solutionDataMap.get(val.L1).L2Data.get(val.L2).status = 0;
      let L1SelectedFlag = 0;
      const selectedL2 = [];
      context.solutionDataMap.get(val.L1).L2Data.forEach((value, key) => {
        L1SelectedFlag = L1SelectedFlag || value.status;
        if (value.status) {
          selectedL2.push(key);
        }
      });
      context.solutionDataMap.get(val.L1).status = L1SelectedFlag ? 1 : 0;
      const addL2Flag = context.solutionBadge.delete(val.L2);
      if (!addL2Flag) {
        context.solutionBadge.delete(val.L1);
        selectedL2.forEach((item) => {
          context.solutionBadge.set(item, val.L1);
        });
      }
    },
    sortSolution(context) {
      // sort solution when dropdown closed
      // L1 first on selected/partially selected/none; then on title
      const L1KeyArray = [...context.solutionDataMap.keys()];
      L1KeyArray.sort((a, b) => {
        const L1ValA = context.solutionDataMap.get(a);
        const L1ValB = context.solutionDataMap.get(b);
        return L1ValB.available - L1ValA.available
        || L1ValB.status - L1ValA.status
        || L1ValA.order - L1ValB.order;
      });
      context.sortedSolution = L1KeyArray.map((L1Key) => {
        const L2KeyArray = [...context.solutionDataMap.get(L1Key).L2Data.keys()];
        L2KeyArray.sort((a, b) => {
          const L2ValA = context.solutionDataMap.get(L1Key).L2Data.get(a);
          const L2ValB = context.solutionDataMap.get(L1Key).L2Data.get(b);
          return L2ValB.available - L2ValA.available
            || L2ValB.status - L2ValA.status
            || L2ValA.order - L2ValB.order;
        });
        return {
          title: L1Key, // L1 id
          L2: L2KeyArray, // L2 id array
          expanded: context.solutionDataMap.get(L1Key).status === 1,
          display: true,
        };
      });
    },
    searchInSortedSolutionV2(context, val) {
      // version 2 for search sorting behaviour
      // val should be the search keyword
      // keep the current sorting, only change the display(have match) and expand(have L2 match)
      const lowerCaseSearchTerm = val.toLowerCase();
      const L2Score = {};
      context.sortedSolution.forEach((solution) => {
        solution.display = false;
        solution.expanded = false;

        const loweCaseTitle = context.solutionDataMap.get(solution.title).title.toLowerCase();
        // solution.title.toLowerCase();

        // L1 match check ---> need to get the title as solution.title is now just ID
        if (loweCaseTitle.includes(lowerCaseSearchTerm)) {
          solution.display = true;
        }
        // L2 match check when L1 available
        if (context.solutionDataMap.get(solution.title)) {
          solution.L2.forEach((L2Solution) => {
            const loweCaseTitleL2 = context.solutionDataMap
              .get(solution.title).L2Data.get(L2Solution).title.toLowerCase(); // need to get L2 title as L2Solution is just ID
            if (loweCaseTitleL2.includes(lowerCaseSearchTerm)) {
              solution.display = true;
              solution.expanded = true;
              L2Score[L2Solution] = 1;
            } else {
              L2Score[L2Solution] = 0;
            }
          });
          solution.L2.sort((a, b) => context.solutionDataMap.get(solution.title).L2Data.get(b).available
            - context.solutionDataMap.get(solution.title).L2Data.get(a).available
            || L2Score[b] - L2Score[a] || a.localeCompare(b));
        }
      });
    },
    searchInSortedSolution(context, val) {
      // val should be the search keyword
      // adjust the display and expanded fields in sortedSolution
      // sort based on matched items match in L1 +100 match in L2 +1
      // score = L1 ? L1 : L2_SUM
      // keep 2 Obj L1{} L2{} to store the score, use score to sort
      // clear search keyword, then call sortSolution
      const L1Score = {};
      const L2Score = {};
      const lowerCaseSearchTerm = val.toLowerCase();

      context.sortedSolution.forEach((solution) => {
        solution.display = false;
        solution.expanded = false;

        const loweCaseTitle = solution.title.toLowerCase();

        if (context.solutionDataMap.get(solution.title).available) {
          if (loweCaseTitle.includes(lowerCaseSearchTerm)) {
            solution.display = true;
            L1Score[solution.title] = 100;
          } else {
            L1Score[solution.title] = 0;
          }

          const L2Array = solution.L2
            .filter((L2Solution) => context.solutionDataMap.get(solution.title).L2Data.get(L2Solution).available > 0);
          L2Array.forEach((L2Solution) => {
            const loweCaseTitleL2 = L2Solution.toLowerCase();
            if (loweCaseTitleL2.includes(lowerCaseSearchTerm)) {
              solution.display = true;
              solution.expanded = true;
              L2Score[L2Solution] = 1;
            } else {
              L2Score[L2Solution] = 0;
            }
          });
          L2Array.sort((a, b) => context.solutionDataMap.get(solution.title).L2Data.get(b).available
            - context.solutionDataMap.get(solution.title).L2Data.get(a).available
            || L2Score[b] - L2Score[a] || a.localeCompare(b));
          solution.L2 = L2Array;
        }
      });
      context.sortedSolution.sort((a, b) => context.solutionDataMap.get(b.title).available
        - context.solutionDataMap.get(a.title).available
        || L1Score[b.title] - L1Score[a.title]
        || (L1Score[b.title] > 0 ? a.title.localeCompare(b.title)
          : ((b.expand - a.expand) || a.title.localeCompare(b.title))));
    },
    toggleL1(context, val) {
      // val should be {L1:title, expand: T/F} --> {L1:ID, expand: T/F}
      const L1 = context.sortedSolution
        .filter((solution) => solution.title === val.L1)[0];
      L1.expanded = val.expand;
    },
    clearAllSelectedSolution(context) {
      context.solutionBadge = new Map();
      context.solutionDataMap.forEach((value, key) => {
        value.status = 0;
        value.L2Data.forEach((value, key) => {
          value.status = 0;
        });
      });
    },
    setMobileMode(context, val) {
      context.mobileMode = val;
    },
  },
};

export default SolutionL2Store;
