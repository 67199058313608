<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import PFActionButtonComponent from '@/components/common/PFActionButtonComponent';
import PFLoadingIndicatorComponent from '@/components/common/PFLoadingIndicatorComponent';
import SingleSelectDropDownComponent from './dropdownItems/SingleSelectDropDownComponent';
import MultiSelectCheckboxDropDownComponent from './dropdownItems/MultiSelectCheckboxDropDownComponent';
import MultiSelectLocationCheckBoxDropDownComponent from './dropdownItems/MultiSelectLocationCheckBoxDropDownComponent.vue';
import PFMultiLevelDropDownComponent from './dropdownItems/PFMultiLevelDropDownComponent';

const REPLACE_PATTERN = /[*%.\\[\](){}+?\-^$|]/g;

export default {
  name: 'PFDropdownsFilterContainer',
  props: {
    title: { type: String, default: 'filer' },
    type: { type: String, default: '' },
    filterType: { type: String, default: '' },
    mobileMode: { type: Boolean, default: false },
    closeMobileFilter: { type: Function, default: () => {} },
    homeMode: { type: Boolean, default: false },
    closeHomeFilter: { type: Function, default: () => {} },
  },
  components: {
    PFLoadingIndicatorComponent,
    MultiSelectCheckboxDropDownComponent,
    PFActionButtonComponent,
    SingleSelectDropDownComponent,
    MultiSelectLocationCheckBoxDropDownComponent,
    PFMultiLevelDropDownComponent,
  },
  data: () => ({
    searchQuery: '',
    isShownFilter: false,
    lock: false,
  }),
  methods: {
    ...mapActions('profileSearch', {
      setLocationVisibility: 'setLocationVisibility',
    }),
    ...mapMutations('profileSearch', {
      setFilterSearchText: 'setFilterSearchText',
      setLocationFilterQuery: 'setLocationFilterQuery',
    }),
    ...mapMutations('solutionL2Store', {
      sortSolution: 'sortSolution',
    }),
    openFilter() {
      this.clearSearchQuery();
      this.isShownFilter = !this.isShownFilter;
    },
    hideFilterItems() {
      if (this.mobileMode) {
        this.closeMobileFilter();
      } else {
        this.clearSearchQuery();
        this.isShownFilter = false;
      }
      if (this.filterType === 'products') {
        this.sortSolution();
      }
    },
    highlightSearchTerm(item, filterType) {
      const replaceFunction = (str) => (str.length === 0 ? str : `<b>${str}</b>`);
      let editedQuery = this.searchQuery.replace(REPLACE_PATTERN, '');

      const extendedCharsAsciiPattern = /[\u0300-\u036f]/g;
      const normalizedSuggestion = item.normalize('NFD').toLowerCase();

      if (extendedCharsAsciiPattern.test(normalizedSuggestion)) {
        if (normalizedSuggestion.replace(extendedCharsAsciiPattern, '').includes(editedQuery.toLowerCase())) {
          const matched = normalizedSuggestion.replace(extendedCharsAsciiPattern, '').match(editedQuery.toLowerCase());
          editedQuery = item.substring(matched.index, matched.index + editedQuery.length);
        }
      }

      const searchValueRegExp = filterType === 'location' ? new RegExp(`^${editedQuery}`, 'i') : new RegExp(editedQuery, 'ig');
      return item.replace(searchValueRegExp, replaceFunction);
    },
    highlightText(item) {
      if (item) {
        const light = this.highlightSearchTerm(item, this.filterType === 'location' ? 'location' : null);
        return light.length > 0 ? light : item;
      }
      return '';
    },
    clearSearchQuery() {
      this.searchQuery = '';
    },
    resolveDistribution() {
      return this.filterOptions[this.filterType];
    },

    resolvePlaceHolderText() {
      if (this.filterType === 'location') {
        return this.$t('filter.search.input.locationPlaceholder');
      }
      return this.$t('filter.search.input.placeholder');
    },
    checkIfCLickedOutside(event) {
      if (event.type !== 'touchstart') {
        this.hideFilterItems();
      } else {
        const dropdown = document.getElementById(this.getID);

        if (!(dropdown && dropdown.contains(event.target))) {
          this.hideFilterItems();
        }
      }
    },
    checkIfCLickedOutside(event) {
      if (event.type !== 'touchstart') {
        this.hideFilterItems();
      } else {
        const dropdown = document.getElementById(this.getID);
        if (!(dropdown && dropdown.contains(event.target))) {
          this.hideFilterItems();
        }
      }
    },
    updateLocaleStorageWithSelectedLocations() {
      const countryNames = this.selectedOptions.location.map((item) => item.title);
      localStorage.setItem('selectedLocation', JSON.stringify(countryNames));
    },
  },
  computed: {
    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
      selectedOptions: (state) => state.selectedOptions,
      loading: (state) => state.loading,
      filterOptions: (state) => state.filterOptions,
      locations: (state) => state.distributions.LOCATION,
      setSolutionFromHomepage: (state) => state.setSolutionFromHomepage,
      selectedIndustry: (state) => state.selectedOptions.industry,
      selectedLocation: (state) => state.selectedOptions.location,
      selectedDesignation: (state) => state.selectedOptions.designation,
    }),
    ...mapState('solutionL2Store', {
      solutionBadge: (state) => state.solutionBadge,
      showMobileFilter: (state) => state.mobileMode,
    }),
    resolveClassForCountSelected() {
      const selectedFilterItems = this.selectedOptions[this.filterType];
      const selectedFilterClassName = 'ds-filter-sort__selected';
      const notSelectedFilterClassName = 'ds-filter-sort__not-selected';
      const resultingClass = selectedFilterItems.length === 0 ? notSelectedFilterClassName : selectedFilterClassName;
      return [resultingClass];
    },
    getID() {
      const homeMode = this.homeMode ? 'home' : 'result';
      return `PF-Dropdown-${homeMode}-${this.filterType}`;
    },
    checkFilterSelected() {
      return [...this.solutionBadge.keys()]?.length > 0
       || [...this.selectedLocation]?.length > 0
       || [...this.selectedIndustry]?.length > 0
       || [...this.selectedDesignation]?.length > 0;
    },
    getHomePageSearchButtonText() {
      if (this.checkFilterSelected) {
        return `${this.$t('button.done')} (${this.countResults} ${this.$tc('results.text', this.countResults)})`;
      }
      return this.$t('button.done');
    },
  },
  mounted() {
    this.updateLocaleStorageWithSelectedLocations();
  },
  watch: {
    selectedLocation: {
      handler() {
        this.updateLocaleStorageWithSelectedLocations();
      },
      deep: true,
    },
    searchQuery() {
      if (this.type === 'locationCheckbox') {
        this.setLocationVisibility(this.searchQuery);
        this.setLocationFilterQuery(this.searchQuery);
      } else if (this.filterType === 'products') {
        this.$store.dispatch('solutionL2Store/searchSolution', this.searchQuery);
      } else {
        const payload = { text: this.searchQuery, filterType: this.filterType };
        this.setFilterSearchText(payload);
      }
    },
  },
  beforeUnmount() {
    if (this.mobileMode || this.homeMode) {
      if (this.type === 'locationCheckbox' && this.searchQuery) {
        this.$store.dispatch('profileSearch/updateLocations', '');
      }
      const payload = { text: '', filterType: this.filterType };
      this.setFilterSearchText(payload);
      this.clearSearchQuery();
      this.isShownFilter = false;
    }
  },
};
</script>

<template>
  <div class="ds-filter-sort__configuration-bar__multi-filter"
    :id="getID">
    <div
      class="ds-filter-sort__configuration-bar_title-block"
      v-click-outside="checkIfCLickedOutside"
      @click="openFilter"
      v-bind:class="resolveClassForCountSelected"
      v-if="!showMobileFilter && !homeMode"
    >
      <div class="ds-filter-sort__configuration-bar__multi-filter__label font-normal">{{ title }}</div>
      <div
        v-if="selectedOptions[filterType].length !== 0
        || (filterType === 'products' && [...solutionBadge.keys()].length > 0)"
        class="ds-number-badge-block"
      >
        <i v-if="filterType !== 'products'"
          class="ds-number-badge"
          :class="selectedOptions[filterType].length >= 10 ? 'ds-number-badge-large' : ''">
          <span>{{ selectedOptions[filterType].length }}</span>
        </i>
        <i v-else
          class="ds-number-badge"
          :class="[...solutionBadge.keys()].length >= 10 ? 'ds-number-badge-large' : ''">
          <span>{{ [...solutionBadge.keys()].length }}</span>
        </i>
      </div>
      <div
        class="ds-filter-sort__dropdown-icon-block"
        v-bind:class="[isShownFilter ? 'filter-sort__dropdown-icon-block-rotate' : '']"
      >
        <i class="ds-filter-sort__dropdown-icon"></i>
      </div>
    </div>
    <div class="ds-dropdown" @click.stop
      :class="[isShownFilter || mobileMode ||  homeMode ? 'ds-open-filters' : '',
      mobileMode ? 'moblie-search-filter' : '',
      homeMode ? 'home-search-filter' : '',
      !mobileMode && !homeMode ? 'normal-dropdown' : '',
      !mobileMode && !homeMode && 'location' == filterType ? 'location-result-page-dropdown' : '',
      !mobileMode && !homeMode && 'engagement' !== filterType
      && 'products' !== filterType ? 'central-dropdown' : '',
      !mobileMode && !homeMode && 'engagement' == filterType ? 'right-dropdown' : '',
      !mobileMode && !homeMode && 'products' == filterType ? 'left-dropdown' : '',
      !mobileMode && filterType==='designation' ? 'filter-no-scroll-bar' : '',]">
      <div v-if="'engagement'!==filterType && 'designation'!==filterType" class="ds-dropdown__search">
        <input
          class="ds-input"
          v-model="searchQuery"
          type="text"
          :placeholder="resolvePlaceHolderText()"
        />
        <i v-if="!searchQuery" class="ds-filter-sort__search-icon ds-icon-search"></i>
        <button v-if="searchQuery" class="search__clear-btn" @click="clearSearchQuery" />
      </div>
      <PFMultiLevelDropDownComponent
        v-if="filterType==='products'"
        :highlightText="highlightText"
        :isShown="(isShownFilter || mobileMode || homeMode)"
        :mobileMode="mobileMode"/>
      <PFLoadingIndicatorComponent :active="loading" />
      <SingleSelectDropDownComponent
        v-if="type === 'singleSelect'"
        :searchQuery="searchQuery"
        :activeOptions="locations"
        :highlightText="highlightText"
        :filterType="filterType"
        :isShown="isShownFilter"
        :closeFilter="openFilter"
      />
      <MultiSelectLocationCheckBoxDropDownComponent
        v-if="type === 'locationCheckbox' && (isShownFilter || mobileMode || homeMode)"
        :activeOptions="resolveDistribution().active"
        :disabledOptions="resolveDistribution().disabled"
        :highlightText="highlightText"
        :filterType="filterType"
        :isShown="(isShownFilter || mobileMode || homeMode)"
        :searchQuery="searchQuery"
      />
      <MultiSelectCheckboxDropDownComponent
        :class="filterType==='engagement' || filterType==='designation' ? 'engagement-checkbox' : 'industry-solution-checkbox'"
        v-if="type === 'checkbox' && (isShownFilter || mobileMode || homeMode) && filterType!=='products'"
        :activeOptions="resolveDistribution().active"
        :disabledOptions="resolveDistribution().disabled"
        :highlightText="highlightText"
        :filterType="filterType"
        :isShown="(isShownFilter || mobileMode || homeMode)"
      />
      <div v-if="type === 'checkbox' || type === 'locationCheckbox' " class="ds-dropdown__action">
        <PFActionButtonComponent
          :action="homeMode ? closeHomeFilter : hideFilterItems"
          :selectedItems="countResults"
          :title="homeMode ? getHomePageSearchButtonText : $t('filter.show.button')"
          :endTitle="homeMode ? '' : $tc('results.text', countResults)"
          :mobileMode="mobileMode"
          :homeMode="homeMode"
        />
      </div>
    </div>
    <div v-if="isShownFilter" class="ds-bg-gold ds-bg-line" />
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.home-search-filter {
  width: 100% !important;
  left: 0 !important;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 4px 14.4px rgba(0, 0, 0, 0.13);
  max-height: 360px;
  z-index: 10;
}
.filter-no-scroll-bar {
  padding-right: 0 !important;

  .ds-checkbox-multiselect {
    padding-right: 12px !important;
  }
  .ds-dropdown__action {
    margin: 8px 12px 0 12px !important;
  }
}

.moblie-search-filter {
  width: 100% !important;
  height: 100%;
  padding: 0 0 0 0 !important;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 200;
  .ds-dropdown__options-wrapper {
    height: calc(100% - 140px);
  }
}
.moblie__configuration-bar {
  display: none !important;
}

.ds-open-filters {
  overflow: hidden;
  display: flex !important;
}

.ds_no_result {
  white-space: normal;
  margin-left: 12px !important;
  color: @color-grey-0 ;
}

.ds-filter-sort__configuration-bar__multi-filter {
  .ds-filter-sort__selected,
  > .ds-filter-sort__configuration-bar__multi-filter__label {
    &:extend(.font-bold);
    text-align  : right;
  }

  .ds-filter-sort__configuration-bar_title-block {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ds-filter-sort__configuration-bar_title-block:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    .ds-filter-sort__configuration-bar_title-block {
      height: 9px;
    }
  }

  .ds-filter-sort__configuration-bar__multi-filter__label {
    font-stretch: normal;
    height: 24px;
    font-size: 16px;
    line-height: 1.5;
    font-style: normal;
    letter-spacing: normal;
    color: @color-black;
    margin-right: 4px;
  }

  .ds-number-badge-block {
    margin-right: 4px;
    .ds-number-badge-large {
      width: 27px !important;
      height: 18px !important;
    }

    .ds-number-badge {
      width: 16px;
      height: 16px;

      div {
        width: 16px;
        height: 16px;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 1px;
        color: @color-white;
        margin-top: 2.5px;
        margin-left: 0.5px;
      }
    }
  }

  .ds-filter-sort__dropdown-icon-block {
    width: 16px;
    height: 16px;
    margin-left: 4px;

    .ds-filter-sort__dropdown-icon {
      color: @color-glance-consultants;
    }
  }

  .filter-sort__dropdown-icon-block-rotate {
    transform: rotate(180deg);
  }

  .ds-filter-sort__not-selected {
    font-weight: normal;
  }

  .ds-dropdown__fide {
    display: none;
  }

  .ds-bg-line {
    height: 4px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: @color-sap-orange;
  }

  .normal-dropdown {
    max-height: 410px;
  }
  .central-dropdown {
    left: calc(-180px + 50%) !important;
  }
  .left-dropdown {
    left: calc(-100px + 50%) !important;
  }
  // .right-dropdown {
  //   left: calc(-230px + 50%) !important;
  // }
  .ds-dropdown {
    object-fit: contain;

    .ds-icon-search {
      color: @color-gray-3 !important;
    }

    .search__clear-btn {
      position: relative;
      outline: none;
      width: 43px;
      height: auto;
      padding-right: 6px;
      padding-left: 6px;
      font-size: 0;
      background: transparent;
      border: solid 0.0625rem @color-gray-3;
      border-left-width: 0;
      cursor: pointer;
    }

    .search__clear-btn:after {
      position: absolute;
      right: 10px;
      content: "";
      color: @color-icon-before;
      display: block;
      font-family: "SAP.com Icons, sans-serif";
      font-size: 18px;
      transform: translate(0, -50%);
    }

    .search__clear-btn.search__clear-btn_alone:after {
      right: 14px;
    }

    .ds-dropdown__action {
      margin: 8px 0 0 12px;

      .ds-button--primary, .ds-button--disabled {
        min-width: 100%;
        justify-content: center;
      }
    }
  }
.moblie-search-filter {
    height: calc(100% - 128px);
    box-shadow: none !important;
    max-height: none !important;
    left: 0 !important;
    top: 128px !important;
    .engagement-checkbox.ds-dropdown__options-wrapper {
      height: calc(100% - 76px);
    }
    .industry-solution-checkbox.ds-dropdown__options-wrapper {
      height: calc(100% - 140px);
    }
    .ds-dropdown__options-wrapper {
      max-height: none !important;
      margin-right: 20px;
    }
    .ds-dropdown__action {
      position: relative;
      height: 64px;
      width: 100%;
      box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 4px 14.4px rgba(0, 0, 0, 0.13);
      display: flex;
      justify-content: center;
      margin-left: 0;
      margin-top: 12px;
    }
    .ds-button--primary, .ds-button--disabled {
      min-width: 0 !important;
      max-width: none !important;
      margin-left: 12px;
      margin-right: 12px;
      width: 100%;
      height: 40px;
      margin-top: 12px;
    }
    .ds-dropdown__search {
      margin: 12px 24px 12px 24px;
    }
    label.ds-checkbox-multiselect {
      padding-left: 18px;
    }
}
  @media screen and (min-width: 768px) {
    .ds-dropdown {
      width: 360px;
    }
  }
}
</style>
